import styled from "styled-components"

import { DeviceMonitoringStatus } from "src/components/Devices/DeviceMonitoringStatus"
import { DeviceLastHeardFromAtIcon } from "src/components/Homes/HomeDetailsV2/HomeDeviceList/DeviceDetailsV2/DeviceLastHeardFromAtIcon"
import { DeviceValues } from "src/components/Homes/HomeDetailsV2/HomeDeviceList/DeviceDetailsV2/DeviceValues"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import { primaryTint } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DeviceSummary({
  homeId,
  device,
  timezone,
  showTitle = true,
}: {
  homeId: string
  device: TDevice
  timezone: IHome["timezone"]
  showTitle?: boolean
}) {
  return (
    <Box>
      {showTitle && <DeviceName>{device.description}</DeviceName>}

      <DeviceValues homeId={homeId} device={device} />

      <DeviceMonitoringStatus device={device} />

      <DeviceLastHeardFromAtIcon
        time={device.last_heard_from_at}
        timezone={timezone}
      />
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
  width: 100%;
  padding: ${spacing.XS2} 0;
`

const DeviceName = styled(MText).attrs(() => ({ variant: "subtitleS" }))`
  flex: 1;
  color: ${primaryTint};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 5ch;
`
