import { useState } from "react"
import styled from "styled-components"

import { Fade } from "@material-ui/core"

import { AdjustableHomeMap } from "src/components/Homes/HomeAddress/AdjustableHomeMap"
import { countryCodeToCountry } from "src/data/countries/countryUtil"
import { IAddress, IHome, ILocation } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { TextButton } from "src/ui/Button/TextButton"
import { ErrorIcon } from "src/ui/ErrorIcon/ErrorIcon"
import { MDetails } from "src/ui/ExpandableSection/MDetails"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeAddressSection({
  homeId,
  location,
  address,
  editable,
}: {
  homeId: IHome["home_id"]
  location?: ILocation
  address?: IAddress
  editable?: boolean
}) {
  const { navigate } = useRouter()
  const { t, langKeys } = useTranslate()
  const [open, setOpen] = useState(false)

  function handleEditClick() {
    navigate(Routes.EditHomeAddress.location(homeId))
  }

  return (
    <MDetails
      title={
        <TitleBox>
          <div>{t(langKeys.address)}</div>
          <Fade in={open}>
            <TextButton
              onClick={handleEditClick}
              disabled={!editable}
              hidden={!editable}
            >
              {t(langKeys.edit)}
            </TextButton>
          </Fade>
        </TitleBox>
      }
      open={open}
      onChange={() => setOpen((prev) => !prev)}
      variant={open ? "accordion" : "default"}
    >
      <MapCardGrid>
        <AdjustableHomeMap
          showTooltip={false}
          showAdjustButton={false}
          height="200px"
          markerWidth="30px"
          location={{
            latitude: location?.latitude || 0,
            longitude: location?.longitude || 0,
          }}
        />

        <div>
          <Warning address={address} location={location} />

          {address && (
            <>
              <MText variant="body">{addressline(address)}</MText>
              <MText variant="bodyS" color="secondary">
                {postline(address)}
              </MText>
            </>
          )}
        </div>
      </MapCardGrid>
    </MDetails>
  )
}

function Warning({
  address,
  location,
}: {
  address?: IAddress
  location?: ILocation
}) {
  const { t, langKeys } = useTranslate()

  if (address && location) {
    return <></>
  }

  return (
    <WarningBox>
      {!address && (
        <WarningBoxRow>
          <StyledErrorIcon state="warning" />
          <MText variant="body">
            {t(langKeys.home_settings_missing_address_info)}
          </MText>
        </WarningBoxRow>
      )}
      {!location && (
        <WarningBoxRow>
          <StyledErrorIcon state="warning" />
          <MText variant="body">
            {t(langKeys.home_settings_missing_location_info)}
          </MText>
        </WarningBoxRow>
      )}
    </WarningBox>
  )
}

function addressline(address: Partial<IAddress>) {
  return [address.street_name1, address.street_name2]
    .filter(Boolean)
    .filter((i) => i && i.length > 0)
    .join(", ")
}

function postline(address: Partial<IAddress>) {
  return [
    address.post_code,
    address.city,
    address.region,
    address.country && countryCodeToCountry(address.country),
  ]
    .filter(Boolean)
    .filter((i) => i && i.length > 0)
    .join(", ")
}

const MapCardGrid = styled.div`
  display: grid;
  grid-gap: ${spacing.M};
  padding-block: ${spacing.M};
`

const WarningBox = styled.div`
  display: grid;
  grid-gap: ${spacing.S};
`

const WarningBoxRow = styled.div`
  display: flex;
`

const StyledErrorIcon = styled(ErrorIcon)`
  margin-right: ${spacing.XS};
`

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
