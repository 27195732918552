import { useState } from "react"
import styled from "styled-components"

import { Tooltip } from "@material-ui/core"

import { SmokingDetectionIcon } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionIcon"
import { SmokingDetectionPopover } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionPopover"
import { CalibrationEmptyState } from "src/components/Homes/HomeDetailsV2/MonitoringCards/CalibrationEmptyState"
import { MonitoringCardV2 } from "src/components/Homes/HomeDetailsV2/MonitoringCards/MonitoringCardV2"
import { IHome, ISmokingDetection } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { CheckCircleIcon } from "src/ui/CheckCircleIcon/CheckCircleIcon"
import { semanticEmergency } from "src/ui/colors"
import { ErrorIcon } from "src/ui/ErrorIcon/ErrorIcon"
import CalibratingIcon from "src/ui/icons/calibrating.svg"
import { spacing } from "src/ui/spacing"

export function SmokingDetectionCardV2({
  smokingDetection,
  violations,
  homeId,
  toggleAllowed,
}: {
  smokingDetection: ISmokingDetection
  violations: IHome["violations"]
  homeId: IHome["home_id"]
  toggleAllowed: boolean
}) {
  const { t, langKeys } = useTranslate()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const tooltipText = toggleAllowed ? "" : t(langKeys.not_enough_permissions)
  const toggleButtonText = t(langKeys.manage)

  function handleClickToggle(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setOpen(false)
    setAnchorEl(null)
  }

  const calibrationDoneAt = smokingDetection.calibration_done_at

  const isCalibrating =
    !!calibrationDoneAt && Boolean(new Date() < new Date(calibrationDoneAt))

  return (
    <div>
      <MonitoringCardV2
        icon={
          <SmokingDetectionIcon
            state={smokingDetection.state}
            status={smokingDetection.status}
            iconProps={{ height: "40" }}
          />
        }
        title={t(langKeys.smoking_detection_title)}
        status={
          <Status
            isCalibrating={isCalibrating}
            state={smokingDetection.state}
            status={smokingDetection.status}
          />
        }
        action={
          <Tooltip title={tooltipText} placement={"top"}>
            <MButton
              variant="subtle"
              key="right"
              onClick={handleClickToggle}
              disabled={!toggleAllowed}
            >
              {toggleButtonText}
            </MButton>
          </Tooltip>
        }
      >
        {isCalibrating && (
          <CalibrationEmptyState
            icon={<CalibratingIcon width={40} />}
            body={t(langKeys.smoking_detection_calibrating_title)}
            description={t(langKeys.smoking_detection_calibrating_body)}
          />
        )}
      </MonitoringCardV2>

      {open && (
        <SmokingDetectionPopover
          homeId={homeId}
          anchorEl={anchorEl}
          violations={violations}
          state={smokingDetection.state}
          onClose={handleClose}
        />
      )}
    </div>
  )
}

function Status({
  isCalibrating,
  state,
  status,
}: {
  isCalibrating: boolean
  state: ISmokingDetection["state"]
  status?: ISmokingDetection["status"]
}) {
  const { t, langKeys } = useTranslate()

  if (isCalibrating) {
    return null
  }

  if (status === "alerting") {
    return (
      <StatusBox>
        <ErrorIcon state="error" svgProps={{ width: 20 }} />
        <Alerting>{t(langKeys.smoking_detection_alert_title)}</Alerting>
      </StatusBox>
    )
  }

  if (state === "enabled") {
    return (
      <StatusBox>
        <CheckCircleIcon svgProps={{ width: 20 }} />
        No cigarette smoke detected
      </StatusBox>
    )
  }

  return null
}

const StatusBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`

const Alerting = styled.div`
  color: ${semanticEmergency};
`
