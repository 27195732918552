import { useParams } from "react-router-dom"
import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState"
import { NoSensorsEmptyState } from "src/components/EmptyState/NoSensors"
import { HomeEventLog } from "src/components/Homes/HomeDetails/HomeEvents/HomeEventLog"
import { ViolationBanner } from "src/components/Homes/HomeDetails/ViolationBanner"
import { GuestSection } from "src/components/Homes/HomeDetailsV2/Guests/GuestSection"
import { HomeAddressSection } from "src/components/Homes/HomeDetailsV2/HomeAddress/HomeAddressSection"
import { HomeBreadcrumbs } from "src/components/Homes/HomeDetailsV2/HomeBreadCrumbs"
import { HomeDetailsActions } from "src/components/Homes/HomeDetailsV2/HomeDetailsActions"
import { HomeDeviceList } from "src/components/Homes/HomeDetailsV2/HomeDeviceList/HomeDeviceList"
import { HomeLoadingState } from "src/components/Homes/HomeDetailsV2/HomeLoadingState"
import { MonitoringCards } from "src/components/Homes/HomeDetailsV2/MonitoringCards/MonitoringCards"
import { ShortcodesSection } from "src/components/Homes/HomeDetailsV2/Shortcodes/ShortcodesSection"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { DEFAULT_HOME_NAME } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchHome,
  usePatchHome,
} from "src/data/organizations/queries/homeQueries"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Editable } from "src/ui/Editable/Editable"
import Svg404 from "src/ui/icons/404.svg"
import { MainView } from "src/ui/Layout/MainView"
import { spacing } from "src/ui/spacing"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function HomeDetailsV2() {
  const { homeId } = useParams<{ homeId: string }>()

  const { t, langKeys } = useTranslate()

  const { org } = useOrganization()
  const patchHome = usePatchHome()
  const fetchHome = useFetchHome({
    orgId: org.id,
    homeId,
  })

  const home = fetchHome.data

  const fetchDevices = useFetchDevices({
    orgId: org.id,
    filter: {
      home_ids: [homeId],
    },
    options: { enabled: !!org.id && !!home?.home_id },
  })

  const devices = fetchDevices.data?.devices || []
  const homeHasSensors = devices.length > 0 && !fetchDevices.isInitialLoading

  const homeAccess = getAccessLogic({
    role: home?.user_role,
  })
  const userCanChangeHomeConfig = homeAccess.hasAdminAccess

  async function handleTitleSave(name: string) {
    await patchHome.mutateAsync({ orgId: org.id, homeId, data: { name } })
  }

  if (fetchHome.isInitialLoading || fetchDevices.isInitialLoading) {
    return <HomeLoadingState />
  }

  if (fetchHome.isError || !home) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  const banner = home?.violations && (
    <ViolationBanner violations={home.violations} />
  )

  return (
    <MainView
      banner={banner}
      breadcrumbs={
        <HomeBreadcrumbs
          homeId={home.home_id}
          name={home.name || DEFAULT_HOME_NAME(home.home_id)}
        />
      }
      title={
        <div>
          <Editable
            onSave={handleTitleSave}
            disabled={!userCanChangeHomeConfig}
          >
            {home.name}
          </Editable>
        </div>
      }
      helmet={home?.name}
      titleBarProps={{
        actionBar: <HomeDetailsActions home={home} />,
        bottomMargin: "small",
      }}
    >
      <Box>
        <LeftSection>
          {homeHasSensors && (
            <MonitoringCards
              home={home}
              editable={userCanChangeHomeConfig}
              devices={devices}
            />
          )}

          <GuestSection homeId={home.home_id} />

          <ShortcodesSection
            homeId={home.home_id}
            guestCommunication={home.guest_communication}
          />

          <HomeAddressSection
            homeId={home.home_id}
            location={home.location}
            address={home.address}
            editable={true} // PRD-2748: Handle permissions
          />
        </LeftSection>

        <RightSection>
          <Tabs
            labels={[
              {
                id: "sensors",
                label: "Sensors",
              },
              {
                id: "log",
                label: t(langKeys.log),
              },
            ]}
          >
            <TabPanel>
              {devices.length >= 1 ? (
                <HomeDeviceList home={home} devices={devices} />
              ) : (
                <NoSensorsEmptyState />
              )}
            </TabPanel>

            <TabPanelWithTopMargin>
              <HomeEventLog homeId={home.home_id} />
            </TabPanelWithTopMargin>
          </Tabs>
        </RightSection>
      </Box>
    </MainView>
  )
}

const Box = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 450px) 1fr;
  column-gap: ${spacing.XL2};
  align-items: flex-start;
`

const LeftSection = styled.div`
  display: grid;
  margin-top: ${spacing.M};
  gap: ${spacing.M};
`

const RightSection = styled.div``

const TabPanelWithTopMargin = styled(TabPanel)`
  padding-top: ${spacing.L};
`
