import styled from "styled-components"

import { Tooltip } from "@material-ui/core"

import { NoiseMonitoringButton } from "src/components/Homes/HomeDetails/NoiseMonitoring/NoiseMonitoringButton"
import { NoiseMonitoringIcon } from "src/components/Homes/HomeDetails/NoiseMonitoring/NoiseMonitoringIcon"
import { MonitoringCardV2 } from "src/components/Homes/HomeDetailsV2/MonitoringCards/MonitoringCardV2"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { NoiseMonitoringState } from "src/data/homeActions/types/noiseMonitoringTypes"
import {
  TDisturbanceMonitoringActive,
  Violations,
} from "src/data/homes/types/homeTypes"
import { IResponseService } from "src/data/homes/types/responseServiceTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { CheckCircleIcon } from "src/ui/CheckCircleIcon/CheckCircleIcon"
import { palette } from "src/ui/colors"
import ResponseServiceIcon from "src/ui/icons/response-service.svg"
import { spacing } from "src/ui/spacing"

export function NoiseMonitoringCardV2({
  homeId,
  monitoringActive,
  violations,
  toggleAllowed,
  devices,
  noiseDuration = 0,
  monitoringState,
  responseService,
}: {
  homeId: string
  monitoringActive: TDisturbanceMonitoringActive
  violations: Violations[] | undefined
  toggleAllowed: boolean
  devices: TDevice[]
  noiseDuration: number
  monitoringState: NoiseMonitoringState | undefined
  responseService: IResponseService | undefined
}) {
  const { t, langKeys } = useTranslate()
  const isActive = !!(monitoringActive && devices?.[0])

  function ActionButton() {
    return (
      <ActivationBox>
        {responseService?.active && (
          <Tooltip
            title={t(langKeys.response_service_body)}
            placement="top"
            arrow
          >
            <div>
              <StyledResponseServiceIcon />
            </div>
          </Tooltip>
        )}
        <NoiseMonitoringButton
          homeId={homeId}
          violations={violations}
          isActive={isActive}
          toggleAllowed={toggleAllowed}
          monitoringState={monitoringState}
        />
      </ActivationBox>
    )
  }

  function noiseDurationToSecs() {
    const duration = noiseDuration

    return Math.ceil(duration / 60)
  }

  function getStatusDescription() {
    if (!isActive) {
      return ""
    }

    switch (monitoringState) {
      case "level_1":
      case "level_2":
      case "level_3":
      case "level_4":
      case "snoozed":
        return t(langKeys.disturbance_warning_state_title, {
          0: noiseDurationToSecs(),
        })

      default:
        return ""
    }
  }
  const statusDescription = getStatusDescription()

  function NoiseStatus() {
    const { t, langKeys } = useTranslate()
    if (isActive) {
      return (
        <StyledIconBox>
          <CheckCircleIcon svgProps={{ width: 20 }} />
          {t(langKeys.disturbance_on_idle_title)}
        </StyledIconBox>
      )
    }
    return null
  }

  return (
    <MonitoringCardV2
      icon={
        <NoiseMonitoringIcon
          active={isActive}
          state={monitoringState}
          iconProps={{ height: 40 }}
        />
      }
      title={t(langKeys.sound_noise_monitoring)}
      status={<NoiseStatus />}
      action={<ActionButton />}
    >
      {!!statusDescription && <div>{statusDescription}</div>}
    </MonitoringCardV2>
  )
}

const ActivationBox = styled.div`
  display: flex;
  column-gap: ${spacing.M};
  align-items: center;
`

const StyledResponseServiceIcon = styled(ResponseServiceIcon)`
  width: 24px;
  color: ${palette.hav};
`

const StyledIconBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`
