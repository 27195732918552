import { useState } from "react"
import styled from "styled-components"

import { useFlagProvider } from "src/components/Flags/useFlagProvider"
import { HomeSettings } from "src/components/Homes/HomeDetails/HomeSettings"
import { FeatureFlag } from "src/constants/featureFlags"
import { IHome } from "src/data/homes/types/homeTypes"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { TextButton } from "src/ui/Button/TextButton"
import { MDialog } from "src/ui/Dialog/MDialog"
import { BodyMixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeDetailsActions({ home }: { home: IHome }) {
  const { t, langKeys } = useTranslate()
  const [openHomeSettings, setOpenHomeSettings] = useState(false)
  const { setFeature } = useFlagProvider()
  const { showNewHomeDetailsView } = useFlags()

  return (
    <AlertActions>
      <TextButton>{t(langKeys.home_details_give_feedback)}</TextButton>
      <TextButton
        onClick={() =>
          setFeature(
            FeatureFlag.SHOW_NEW_HOME_DETAILS_VIEW,
            !showNewHomeDetailsView
          )
        }
      >
        {t(langKeys.home_details_switch_to_original_version)}
      </TextButton>
      <MButtonLegacy color="default" onClick={() => setOpenHomeSettings(true)}>
        {t(langKeys.settings)}
      </MButtonLegacy>

      <MDialog
        title={t(langKeys.home_settings)}
        open={openHomeSettings}
        onClose={() => setOpenHomeSettings(false)}
        showActionBar={false}
        hideClose
      >
        <HomeSettings home={home} />
      </MDialog>
    </AlertActions>
  )
}

const AlertActions = styled.div`
  display: flex;
  column-gap: ${spacing.XL};
  ${BodyMixin};
`
