import { useState } from "react"
import styled from "styled-components"

import { Drawer } from "@material-ui/core"

import { DeviceDetailsV2 } from "src/components/Homes/HomeDetailsV2/HomeDeviceList/DeviceDetailsV2/DeviceDetailsV2"
import { DeviceSummary } from "src/components/Homes/HomeDetailsV2/HomeDeviceList/DeviceDetailsV2/DeviceSummary"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import { divider, hoverRowBackground } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export function HomeDeviceList({
  home,
  devices,
}: {
  home: IHome
  devices: TDevice[]
}) {
  const [selectedDeviceId, setSelectedDeviceId] = useState<
    TDevice["device_id"] | null
  >()

  function handleClickRow(deviceId: TDevice["device_id"]) {
    setSelectedDeviceId(deviceId)
  }

  function handleCloseDrawer() {
    setSelectedDeviceId(null)
  }

  const selectedDevice = devices.find((d) => d.device_id === selectedDeviceId)

  return (
    <Box>
      {devices.map((device) => (
        <Row
          key={device.device_id}
          onClick={() => handleClickRow(device.device_id)}
        >
          <DeviceSummary
            homeId={home.home_id}
            device={device}
            timezone={home.timezone}
          />
        </Row>
      ))}

      <Drawer
        anchor="right"
        open={!!selectedDevice}
        onClose={handleCloseDrawer}
      >
        {!!selectedDevice && (
          <DrawerBox>
            <DeviceDetailsV2
              device={selectedDevice}
              home={home}
              onClose={handleCloseDrawer}
            />
          </DrawerBox>
        )}
      </Drawer>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
`
const Row = styled.div`
  border-bottom: 1px solid ${divider};
  display: flex;
  gap: ${spacing.XS};
  padding: ${spacing.XS} 0;
  align-items: center;

  &:hover {
    background: ${hoverRowBackground};
    cursor: pointer;
  }
`

const DrawerBox = styled.div`
  width: 70vw;
  max-width: 850px;
  position: relative;
`
