import { useEffect, useState } from "react"
import styled from "styled-components"

import { DynamicSecurityAlarmIcon } from "src/components/Homes/HomeDetails/SecurityAlarm/DynamicSecurityAlarmIcon"
import {
  SecurityAlarmButton,
  SecurityAlarmButtonLabel,
} from "src/components/Homes/HomeDetails/SecurityAlarm/SecurityAlarmButton"
import { MonitoringCardV2 } from "src/components/Homes/HomeDetailsV2/MonitoringCards/MonitoringCardV2"
import { getUIAlarmState } from "src/data/homeActions/logic/securityAlarm"
import { useFetchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { AlarmStatus } from "src/data/homes/types/alarmTypes"
import { DEFAULT_PROFILE_ID, IHome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { CheckCircleIcon } from "src/ui/CheckCircleIcon/CheckCircleIcon"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function SecurityAlarmCardV2({
  home,
  toggleAllowed,
}: {
  home: IHome
  toggleAllowed: boolean
}) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const [now, setNow] = useState(Date.now())
  const {
    alarm_status: alarmStatus,
    grace_period_expires_at: gracePeriodExpiresAt,
    grace_period_secs: gracePeriodSecs,
    silent_alarm: silentAlarm,
    alarm_mode: alarmMode,
  } = home.alarm || {}

  const fetchProfile = useFetchProfile({
    id: home.profile_id || DEFAULT_PROFILE_ID,
  })
  const deadline = !!gracePeriodExpiresAt
    ? new Date(gracePeriodExpiresAt).getTime()
    : null
  const uiAlarmState = alarmStatus && getUIAlarmState(alarmStatus, deadline)

  const hasMemberAccess = getAccessLogic({
    role: org.user_role,
  }).hasMemberAccess

  useEffect(() => {
    if (uiAlarmState !== "on_grace_period") {
      return
    }
    setNow(Date.now())
    const interval = setInterval(() => {
      setNow(Date.now())
    }, 1000)
    return () => clearInterval(interval)
  }, [uiAlarmState])

  return (
    <MonitoringCardV2
      icon={
        <DynamicSecurityAlarmIcon
          now={now}
          deadline={deadline}
          gracePeriodSecs={gracePeriodSecs}
          alarmStatus={uiAlarmState}
          iconProps={{ height: "40px", width: "40px" }}
          progressBarProps={circleConfig}
        />
      }
      title={t(langKeys.home_security_alarm_title)}
      status={
        <SecurityAlarmStatus
          uiAlarmState={uiAlarmState}
          alarmStatus={alarmStatus}
          deadline={deadline}
          now={now}
        />
      }
      action={
        <SecurityAlarmButton
          homeId={home.home_id}
          showAlarmArmDelayHint={
            !!showAlarmDelayHint(
              fetchProfile.data?.security_alarm?.instantly_turn_on
            )
          }
          alarmStatus={alarmStatus}
          gracePeriodExpiresAt={gracePeriodExpiresAt}
          gracePeriodSecs={gracePeriodSecs}
          silentAlarm={silentAlarm}
          alarmMode={alarmMode}
          toggleAllowed={toggleAllowed || hasMemberAccess}
          violations={home.violations}
        />
      }
    />
  )
}

function SecurityAlarmStatus({
  uiAlarmState,
  alarmStatus,
  now,
  deadline,
}: {
  uiAlarmState: AlarmStatus | undefined
  alarmStatus: AlarmStatus | undefined
  now: number
  deadline: number | null
}) {
  const { t, langKeys } = useTranslate()

  switch (alarmStatus) {
    case "off_grace_period": {
      return <div>{t(langKeys.alarm_grace_period_expired_title)}</div>
    }

    case "on": {
      return (
        <StyledIconBox>
          <CheckCircleIcon svgProps={{ width: 20 }} />
          {t(langKeys.home_security_alarm_on_body)}
        </StyledIconBox>
      )
    }

    case "off": {
      return null
    }

    case "on_grace_period": {
      const statusOn =
        uiAlarmState && ["on", "on_grace_period"].includes(uiAlarmState)
      return (
        <div>
          <MBanner type={statusOn ? "info" : "neutral"} size="small">
            <SecurityAlarmButtonLabel
              now={now}
              deadline={deadline}
              alarmStatus={uiAlarmState}
            />
          </MBanner>
        </div>
      )
    }

    case "critical_event": {
      return (
        <StyledIconBox>
          {t(langKeys.alarm_grace_period_expired_title)}
        </StyledIconBox>
      )
    }

    default: {
      throw Error(`Got an unexpected AlarmStatus: ${alarmStatus}`)
    }
  }
}

/* We only want to show the "alarm delay" hint when it makes sense
  for the user to see it, which is when `instantly_turn_on` === `false`. Since
  `securityAlarm` can be undefined if the request hasn't finished loading yet,
  or the user doesn't have permissions to fetch profiles for this home or if
  the home is missing the security alarm object, we check that the the value
  is not undefined before
  */
function showAlarmDelayHint(instantlyTurnOn: boolean | undefined) {
  if (instantlyTurnOn === undefined) {
    return false
  }

  return !instantlyTurnOn
}

const circleConfig = {
  height: 40,
  width: 40,
  x: 20,
  y: 20,
  radius: 16.8,
}

const StyledIconBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`
